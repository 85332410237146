import React, {useState, useEffect, useRef, forwardRef} from "react";
import Form from 'react-bootstrap/Form';
import {InputGroup, Spinner } from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
import EmployeeTransferList from "../EmployeeTransferList";
import { API } from "../../Controllers/API";
import { Paper, Typography, Box } from "@mui/material";
import axios from "axios";
import uuid from "react-uuid";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import delete_btn from '../../assets/css/images/delete_btn.png'
import {Button} from "@mui/material";
import { Link } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import pipInstructions from '../../json/pip-instructions.json'
import { formatDate } from "../../classes/global-functions";

import { Update } from "@mui/icons-material";


const BackBtn = forwardRef((props, ref) => {
  return <a {...props}
  onClick={props.OnClickThis}
  ref={ref} ></a>
})




const PIP = (props)=>{
  const itemRef = useRef(null);
  const [spinner,setSpinner] = useState(false)
  const data = JSON.parse(sessionStorage.getItem('profile-data'))
  const [update,setUpdate] = useState('')
  const [sendEmailCheck,setSendEmailCheck] = useState(true)
  const [areasOfConcern,setAreasOfConcern] = useState('')
  const [observations,setObservations] = useState('')
  const [goals,setSetGoals] = useState([])
  const [goalTitle,setGoalTitle] = useState('')
  const [resources,setResources] = useState('')
  const [expectations, setExpectations] = useState('')
  const [loading, setLoading] = useState(true);
  const [reviewPeriod, setReviewPeriod] = useState('')
  const [department, setDepartment] = useState('')
  const [supervisor, setSupervisor] = useState('')
  const [supervisorShow, setSupervisorShow] = useState(true);
  const [departmentShow, setDepartmentShow] = useState(true);
  const [reviewee, setReviewee] = useState('')
  const [status, setStatus] = useState('Not Yet Sent To Be Signed')

  //Checkpoint State
  const [checkpoints, setCheckpoints] = useState([])
  const [checkpointGoal, setCheckpointGoal] = useState('')
  const [checkpointDate, setCheckpointDate] = useState('')
  const [checkpointFollowUpType, setCheckpointFollowUpType] = useState('')
  const [checkpointProgressExpected, setCheckpointProgressExpected] = useState('')


  const [switchOne, setSwitchOne] = useState(false)
  const [carbonCopyList, setCarbonCopyList] = useState([])
const [ccName, setCcName] = useState('')
const [ccEmail, setCcEmail] = useState('')
  const [managers, setManagers] = useState([{
    dateOfLastReview: "NA",
  email: "null",
  id: "null",
  name: "null",
  reviews: [''],
  role: "Manager"
  }])
  const [employees, setEmployees] = useState([{
    dateOfLastReview: "NA",
  email: "null",
  id: "null",
  name: "null",
  reviews: [''],
  role: "Employee"
  }])
  const handleSupervisor = (e) => setSupervisor(e);
  const handleDepartment = (e) => setDepartment(e);
  const handleReviewPeriod = (e) => setReviewPeriod(e);
  const handleAreasOfConcern = (e) => setAreasOfConcern(e);
  const handleObservations = (e) => setObservations(e);
  const handleResources = (e) => setResources(e);
  const handleExpectations = (e) => setExpectations(e);
  const handleCcName = (e) =>{
    setCcName(e)
    console.log(ccName)
  }
  
  
  const handleCcEmail = (e) =>{
    setCcEmail(e)
    console.log(ccEmail)
  }


  let date = new Date()
  let parsedDate = date.toLocaleDateString()
  const SetupReview = async ()=>{
setSpinner(true)
  let reviewId = uuid()
  let reviewObject = 
  {
    reviewId: reviewId,
    reviewee: reviewee,
    reviewFrom:{id:data.id, name:data.name,email:data.replyToEmail},
    reviewType: 'Performance Improvement Plan',
    goalsArray: goals,
    dateCreated: parsedDate,
    department:department,
    supervisor:supervisor,
    reviewPeriod:reviewPeriod,
    areasOfConcern:areasOfConcern,
    observations:observations,
    resources:resources,
    expectations:expectations,
    ccGroup:carbonCopyList,
    checkpoints:checkpoints,
    status:status,
    signature:'',
    signatureDate:'',
    link:`http://localhost:3000/?a=${data.id}&b=${reviewee.id}&c=${reviewId}`,
  }

  console.log(reviewObject)
console.log(data.id)
  let response = await axios.post(API+'add-pip',{id:data.id,reviewObject:reviewObject},(err,response)=>{
       if(err){

       } else{
        console.log(response.data)
        return response.data
       }
  })
  itemRef.current.click()
          setSpinner(false)
  console.log(response)

  }
  
  useEffect(()=>{
    axios.post(API,{email:data.email}).then((response)=>{
      
    return response
  
        }).then((response)=>{
          console.log(response.data[0])
          
          return response

          
         
        }).then(((response)=>{
          setManagers(response.data[0].employees.filter(i=>{return (i.role === "Manager")}))
          setEmployees(response.data[0].employees.filter(i=>{return (i.role === "Employee")}))
          // setSavedWorksheets(response.data[0].savedWorksheets[0].worksheets)
          
          setUpdate(!update)
          setLoading(false)
        }))
  },[])

  const handleRevieweeSelect = (e) =>{
    setReviewee(e)
  
  
    console.log(reviewee)
    
    
  }

  const addGoal = ()=>{

    let value = {
      id: uuid(),
      goalTitle: goalTitle,
      startDate:'',
      compDate:'',
      activity:'',
      howTo:'',
      goalNumber: goals.length + 1

    }

    goals.push(value)
    setGoalTitle('')

  }


  const UpdateGoal = (a, val, type)=>{
    
    let index = goals.findIndex((obj => obj.id == a))

    if(type === 'Start-Date'){
      goals[index].startDate = val

      console.log(goals)
    }

    if(type === 'Comp-Date'){
      goals[index].compDate = val

      console.log(goals)
    }

    if(type === 'Activity'){
      goals[index].activity = val

      console.log(goals)
    }

    if(type === 'Accomplish'){
      goals[index].howTo = val

      console.log(goals)
    }

    
    
  }

  const AddCheckpoint = ()=>{
    let checkpoint = {
        id:uuid(),
        checkpointGoal:checkpointGoal,
        dateOfCheck:checkpointDate,
        checkpointFollowUpType:checkpointFollowUpType,
        checkpointProgressExpected:checkpointProgressExpected,
        notes:''
    }
    if(checkpointGoal != '---'){
      
    checkpoints.push(checkpoint)
    setCheckpointGoal('---')
    setCheckpointDate('')
    setCheckpointFollowUpType('')
    setCheckpointProgressExpected('')

  } else{ 
    alert('Please select a goal.')
  }
    console.log(checkpoint)
  }

  const DeleteCheckPoint = (a)=>{
    let filter = checkpoints.filter(i=>{
      return  (a !== i.id)
    })
    console.log(filter)

    setCheckpoints(filter)
  }

  const DeleteGoal = (id)=>{
    let index = goals.findIndex((obj => obj.id == id))
    let arr = goals.filter(o=>{return (o.id != id)})

    setSetGoals(arr)
    console.log(goals)

  }

    return (
        <div>
        <Form.Group className="mb-3">
       
        <Form.Label><Typography sx={{fontSize:'16px'}} variant="subtitle2">Select the person that this plan is being created for:</Typography></Form.Label>
        <Form.Select  onChange={(e)=>{
          let val = JSON.parse(e.target.value)
      
          handleRevieweeSelect(val)
  
        
        }}>
          <option value="null">Person that this plan is being created for...</option>
          <optgroup label="Managers">
         {managers.map(i=>{
            let val = {
              id:i.id,
              firstName:i.firstName,
              lastName:i.lastName,
              name:i.name, 
              email:i.email,
              status:'pending'
            }
          return <option  value={JSON.stringify(val)}>{i.name}</option>
         })}
         </optgroup>


         <optgroup label="Employees">
         {employees.map(i=>{
          let val = {
            id:i.id,
            firstName:i.firstName,
            lastName:i.lastName,
            name:i.name, 
            email:i.email,
            status:'pending'
          }
          return <option value={JSON.stringify(val)}>{i.name}</option>
         })}
         </optgroup>
        </Form.Select>
      </Form.Group>

        <Accordion defaultActiveKey="0">

        <Accordion.Item eventKey="0">
        <Accordion.Header><Typography variant="button"><b>Instructions</b></Typography></Accordion.Header>
        <Accordion.Body>
        <Box sx={{textAlign:'start'}}>
           <Typography variant="button" sx={{marginBottom:'25px'}}><b>How to Conduct a Performance Improvement Plan (PIP)</b></Typography>
           <Typography variant="p"><b>Instructions:</b> The Performance Improvement Plan (PIP) should be used when an employee receives a low to unsatisfactory rating on their annual performance evaluation. It may also be used any time an employee’s performance or conduct fails to meet the supervisor’s expectations.</Typography>
            <br></br>
            <br></br>
          {pipInstructions.map((i,index)=>{
           
            return (
              <div>
            <Typography variant="button"><b>{index + 1}.	{i.title}</b></Typography>
            <ul>
              {i.details.map(o=>{
              return(
                <li>{o}</li>
              )})}
              </ul>
            </div>
            )
          })}
            
               </Box>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header><Typography variant="button"><b>Purpose of Performance Improvement Plan</b></Typography></Accordion.Header>
        <Accordion.Body>
        <Form.Group className="mb-3">
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Typography variant="subtitle2">The purpose of this Performance Improvement Plan (PIP) is to define serious areas of concern, gaps in your work performance, reiterate expectations, and allow you the opportunity to demonstrate improvement and commitment.</Typography>
          <hr></hr>
        <Typography variant="button"><b>Areas of Concern:</b></Typography>
        <Typography sx={{marginBottom:'10px'}}  variant="subtitle2">List specific areas where the employee failed to meet expectations</Typography>
   
       
        <Form.Control onChange={(e)=>handleAreasOfConcern(e.target.value)}  as="textarea" rows={3} />
        <hr></hr>
        <Typography variant="button"><b>Observations, Previous Discussions, or Additional Training Provided:</b></Typography>
        <Typography sx={{marginBottom:'10px'}} variant="subtitle2">List any previous times the issues have been addressed, the context, and the outcome of discussions or training</Typography>
   
       
        <Form.Control onChange={(e)=>handleObservations(e.target.value)} as="textarea" rows={3} />
      </Form.Group>
      </Form.Group>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header><Typography variant="button"><b>Improvement Goals</b></Typography></Accordion.Header>
        <Accordion.Body>
        <Typography variant="subtitle2">List the goals related to areas of concern to be improved and addressed:</Typography>
        <hr></hr>
        <InputGroup className="mb-3">
        <Form.Control
          placeholder="Add Goal"
          aria-label="Add Goal"
          aria-describedby="basic-addon2"
          onChange={(e)=>setGoalTitle(e.target.value)}
          value={goalTitle}
        />
        <Button  onClick={()=>{addGoal()}} style={{color:'white', backgroundColor:'#1979E6'}} id="button-addon2">
          Add
        </Button>
      </InputGroup>
      
        <Typography style={{display: goals.length > 0 ? 'block': 'none', fontSize:'14px'}} variant="button"><b><span style={{color:'red'}}>STOP:</span> Before continuing to the next part you will need to click each goal dropdown and detail the activity.</b></Typography>
        <Accordion>
        {goals.map((i, index)=>{
          return (
          <Accordion.Item key={i.id} eventKey={index}>
        <Accordion.Header>Goal {index + 1}: {i.goalTitle}</Accordion.Header>
        <Accordion.Body>
          <Box sx={{textAlign:'end'}}><a style={{float:'right', cursor:'pointer'}} onClick={()=>DeleteGoal(i.id)}><img width="20px" height="20px" src={delete_btn}/></a></Box>
        <Row className="mb-3">
        <Form.Group as={Col} >
        <Form.Label><Typography variant="button">Start Date:</Typography></Form.Label>
          <Form.Control onChange={(e)=>{
            let val = e.target.value
            
            UpdateGoal(i.id, val,'Start-Date')}} type="text" placeholder="Start Date" />
        </Form.Group>

        <Form.Group as={Col}>
          <Form.Label><Typography variant="button">Projected Completion Date:</Typography></Form.Label>
          <Form.Control onChange={(e)=>{
            let val = e.target.value
            
            UpdateGoal(i.id, val,'Comp-Date')}} type="text" placeholder="Projected Completion Date" />
        </Form.Group>
      </Row>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label><Typography variant="button">Activity:</Typography></Form.Label>
        <Form.Control onChange={(e)=>{
            let val = e.target.value
            
            UpdateGoal(i.id, val,'Activity')}} as="textarea" rows={3} />
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
      <Form.Label><Typography variant="button">How to ACCOMPLISH:</Typography></Form.Label>
        <Form.Control onChange={(e)=>{
            let val = e.target.value
            
            UpdateGoal(i.id, val,'Accomplish')}} as="textarea" rows={3} />
      </Form.Group>
        </Accordion.Body>
      </Accordion.Item>
      
          )
        })}
        </Accordion>
   
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header><Typography variant="button"><b>Resources and Expectations</b></Typography></Accordion.Header>
        <Accordion.Body>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label><Typography variant="button"><b>Resources:</b></Typography></Form.Label>
        <Form.Label><Typography variant="subtitle2">Listed below are resources available to you to complete your Improvement activities (may include other people’s time or expertise, management support, training materials and activities, or time away from usual responsibilities.)</Typography></Form.Label>

        <Form.Control onChange={(e)=>handleResources(e.target.value)}    as="textarea" rows={3} />
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
      <Form.Label><Typography variant="button"><b>Expectations:</b></Typography></Form.Label>
      <Form.Label><Typography variant="subtitle2">The following performance standards must be accomplished to demonstrate progress towards achievement of each Improvement goal.</Typography></Form.Label>
        <Form.Control onChange={(e)=>handleExpectations(e.target.value)}   as="textarea" rows={3} />
      </Form.Group>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="4">
        <Accordion.Header><Typography variant="button"><b>Progress Checkpoints</b></Typography></Accordion.Header>
        <Accordion.Body>
      <Typography variant="button"><b>Add Checkpoints:</b></Typography>
        <Typography variant="subtitle2">In this section you can add checkpoints for each activity to check progress. You will also be reminded of these checkpoints in the Evaluation Calendar and on the Dashboard under upcoming evaluations.</Typography>
        <hr></hr>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label><Typography variant="subtitle2"><b>Select the goal you would like to add a checkpoint to:</b></Typography></Form.Label>
        <Form.Select
          placeholder="Add Goal"
          aria-label="Add Goal"
          aria-describedby="basic-addon2"
          value={checkpointGoal}
          onChange={(e)=>setCheckpointGoal(e.target.value)}
         
        ><option>---</option>
          {goals.map(i=>{
          return (<option key={i.id}>{i.goalTitle}</option>)
        })}</Form.Select>
      </Form.Group>
      <Row className="mb-3">
            <Form.Group  as={Col} >
        <Form.Label><Typography variant="subtitle2"><b>Checkpoint Date</b></Typography></Form.Label>
        <Form.Control value={checkpointDate} onChange={(e)=>setCheckpointDate(e.target.value)} type="date"  />
      </Form.Group>
      <Form.Group as={Col} >
        <Form.Label><Typography variant="subtitle2"><b>Type of Follow-Up</b></Typography></Form.Label>
        <Form.Control value={checkpointFollowUpType} onChange={(e)=>setCheckpointFollowUpType(e.target.value)} type="text" placeholder="exp: Email, Call, Meeting.." />
      </Form.Group>
      
      </Row>

      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label><Typography variant="subtitle2"><b>Progress Expected</b></Typography></Form.Label>
        <Form.Control value={checkpointProgressExpected} onChange={(e)=>setCheckpointProgressExpected(e.target.value)} placeholder="Detail the progress that is expected with this goal..." as="textarea" rows={3} />
      </Form.Group>
      <div style={{textAlign:'end'}}>
      <Button onClick={AddCheckpoint} variant="contained">Add Checkpoint</Button>  
      </div>
       
      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650,display: (checkpoints.length > 0) ?'table':'none' }} aria-label="simple table">
        <TableHead>
          <TableRow>
          <TableCell>Activity</TableCell>
          <TableCell>Checkpoint Date</TableCell>
          <TableCell>Follow-up Type</TableCell>
          <TableCell>Progress Expected</TableCell>
          <TableCell>Actions</TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
        {
          checkpoints.map(i=>{
          let date = new Date(i.dateOfCheck)
            return(
              <TableRow>
              <TableCell>{i.checkpointGoal}</TableCell>
              <TableCell>{formatDate(date)}</TableCell>
              <TableCell>{i.checkpointFollowUpType}</TableCell>
              <TableCell>{i.checkpointProgressExpected}</TableCell>
              <TableCell><Button onClick={()=>DeleteCheckPoint(i.id)} sx={{color:'red'}}>Delete</Button></TableCell>

            </TableRow>
            )
          })
        }
            </TableBody>
      </Table>
    </TableContainer>

        </Accordion.Body>
      </Accordion.Item>


      <Accordion.Item eventKey="5">
        <Accordion.Header><b>FINALIZE</b></Accordion.Header>
        <Accordion.Body>
      <Form.Group>
        <Form.Label><b>Enter Today's Date:</b></Form.Label>
     <Form.Control onChange={(e)=>handleReviewPeriod(e.target.value)}  type="date"/>
      </Form.Group>
       
   

      <Typography style={{marginTop:'10px'}}><b>Extra Reviewee Information(optional)</b></Typography>
      <Form.Group>
     
          
 
      <InputGroup className="mb-3">
        <InputGroup.Checkbox onChange={()=>{setDepartmentShow(!departmentShow); console.log(!departmentShow);
        if(departmentShow === false){
           setDepartment('')
           console.log(departmentShow)}}} aria-label="Checkbox for following text input" />
        <Form.Control value={department} onChange={(e)=>handleDepartment(e.target.value)} disabled={departmentShow} placeholder="Add Department" aria-label="Text input with checkbox" />
     </InputGroup>

     <InputGroup className="mb-3">
        <InputGroup.Checkbox onChange={()=>{setSupervisorShow(!supervisorShow); console.log(!supervisorShow);
        if(supervisorShow === false){
           setSupervisor('')
           console.log(supervisor)}}} aria-label="Checkbox for following text input" />
        <Form.Control value={supervisor} onChange={(e)=>handleSupervisor(e.target.value)} disabled={supervisorShow} placeholder="Supervisor" aria-label="Text input with checkbox" />
     </InputGroup>

  
      </Form.Group>

      <hr></hr>
      <Form.Group>
        <Form.Label><Typography><b>WOULD YOU LIKE TO EMAIL THIS PLAN TO THE EMPLOYEE FOR A SIGNATURE?</b></Typography></Form.Label> 
          <Form.Check 
            type="checkbox"
            label={`Check to email this PIP to the employee immediately upon completion. If you prefer to wait and email the plan at a later date, leave the box unchecked. When you are ready to send the plan to the employee, go to the PIP section and select the Send Plan option in the Actions column of the applicable employee’s plan status.`}
            checked={!sendEmailCheck}
            onChange={()=>{
              setSendEmailCheck(!sendEmailCheck)

              if(sendEmailCheck){
                  setStatus('Pending Signature..')
                  
              } else{
                setStatus('Not Yet Sent To Be Signed')
              }
              console.log(sendEmailCheck)
            }}
          />
      </Form.Group>
      <hr></hr>
         <Typography variant="buttom"><b>ADD CC's</b></Typography>
         <Form.Group>
          <Form.Label>Add any one you would like to Copy on the completed review email.</Form.Label>
         <InputGroup className="mb-3">
        <Form.Control
          placeholder="Recipient's Name"
          value={ccName}
          onChange={(e)=>{
            handleCcName(e.target.value)
          }}
          aria-describedby="basic-addon2"
        />
         <Form.Control
          placeholder="Recipient's Email"
          onChange={(e)=>{
            handleCcEmail(e.target.value)
          }}
          value={ccEmail}
          aria-describedby="basic-addon2"
        />
        <Button onClick={()=>{
            let val = {
              id:uuid(),
              name: ccName,
              email: ccEmail
            }
            setCcEmail('')
            setCcName('')
            carbonCopyList.push(val)
            setUpdate(!update)
          }} style={{color:'white', backgroundColor:'#1979E6'}} id="button-addon2">
          Add
        </Button>
      </InputGroup>
      </Form.Group>
      <Form.Group>
      <Table striped bordered hover>
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
        </tr>
      </thead>
      <tbody>
       {carbonCopyList.map(i=>{
        return(
          <tr>
       
          <td>{i.name}</td>
          <td>{i.email}</td>
          <td style={{textAlign:'center'}}><Button
          onClick={()=>{
            let filter = carbonCopyList.filter(a=>{
              return (i.id !== a.id)
            })
            setCarbonCopyList(filter)
            setUpdate(!update)
             console.log(i.id)
          }}
          style={{padding:'2px', color:'red'}}>Delete</Button></td>
        </tr>
        )
       })}
      </tbody>

    
    </Table>

    
      <hr></hr>
      <div>
       <Link to="demo" onClick={()=> localStorage.setItem('refer', JSON.stringify(
        {
  
          reviewee: reviewee,
          reviewFrom:{id:data.id, name:data.name,email:data.replyToEmail},
          reviewType: 'Performance Improvement Plan',
          goalsArray: goals,
          dateCreated: parsedDate,
          department:department,
          checkpoints:checkpoints,
          supervisor:supervisor,
          reviewPeriod:reviewPeriod,
          areasOfConcern:areasOfConcern,
          observations:observations,
          resources:resources,
          expectations:expectations,
          signature:'',
          signatureDate:''
    
       
        }))} target="_blank"> <Button style={{backgroundColor:'#1A7AE6',  color:"white"}}>Preview the Plan</Button></Link>

     <Button onClick={SetupReview} style={{float:'right',backgroundColor:'#1A7AE6', color:"white"}}>{!sendEmailCheck ? 'Create and Send':'Create'} Plan <span> <Spinner style={{display: spinner ? '':'none'}}  size="sm"  animation="border" variant="light"  /></span></Button>

       
      </div>
      <BackBtn ref={itemRef} OnClickThis={props.OnClickSend}/>
      </Form.Group>
        </Accordion.Body>
      </Accordion.Item>
      </Accordion>


    </div>
    )
}

export default PIP;